import React from 'react';
import ProjectData from '../data/projects.json';
import './Home.scss';
import Project from './Project/Project';

const Home = () => {

	return (
		<main className="project-grid">
			{ProjectData.map((project, key) => {
				return(
					<Project 
						name={project.name} 
						url={project.url} 
						description={project.description} 
						studio={project.studio} 
					/>
				)
			})}
		</main>
	);

}

export default Home;