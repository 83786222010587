import React from 'react';
import './Project.scss';

const Project = ( props ) => {

	const formatClass = ( data ) => {
		var formattedClass = data.replace(/\s+/g, '-').toLowerCase();
		formattedClass = formattedClass.replace(/&/g, "and");
		return "project--description__"+formattedClass;
	}

	return (
		<a href={props.url} aria-label={props.name} target="_blank" rel="noopener noreferrer" className="project--item" >
			<article className="project--wrap">
				<div className="project--wrap-inner">
					<div className="project--details">
						<header>
							<h2>{props.name}</h2>
							<p className={`${formatClass(props.name)}`}>{props.description}</p>
						</header>
						{props.studio &&
							<p>Design: {props.studio}</p>
						}
					</div>
				</div>
			</article>
		</a>
	);

}

export default Project;