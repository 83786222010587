import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../components/Home';
import About from '../components/About';
import Header from '../components/Header';

class AppRouter extends Component {

	render() {
		return(
			<Router>
					<Header />
					<Switch>
						<Route exact path="/">
							<Home />
						</Route> 					
						<Route exact path="/about">
							<About />
						</Route>
					</Switch>
			</Router>
		);
	}

};

export default AppRouter;