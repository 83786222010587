import React from 'react';
import './About.scss';

const About = () => {

	return ( 
		<main>
			<section className="about--wrapper">
				<p>I'm Sam, a digital designer and front end developer based in Sussex.</p>

				<p>My key skills include semantic HTML &amp; CSS, JavaScript and content management 
				system creation, along with an interest in React.js, Vue.js and Gatsby.</p>

				<p>With a background in marketing and copywriting, I'm equally happy to consult on other
				aspects of digital such as site audits, SEO and email campaigns.</p>

				<ul>
					<li>
						<a href="mailto:talk@samwatts.net">Email</a>
					</li>
					<li>
						<a target="blank" rel="noreferrer noopener" href="https://twitter.com/SamWatts1988">Twitter</a>
					</li>
					<li>
						<a target="blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/samwatts1988/">LinkedIn</a>
					</li>
					<li>
						<a target="blank" rel="noreferrer noopener" href="https://www.instagram.com/samwatts_developer/">Instagram</a>
					</li>
				</ul>
			</section>
		</main>
	)

}

export default About;