import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.scss';

const Header = () => {

	return (
		<header className="site-header">
			<nav className="site-navigation">
				<ul>
					<li>
						<NavLink exact={true} activeClassName='is-active' to="/">Work</NavLink>
					</li>
					<li>
						<NavLink exact={true} activeClassName='is-active' to="/about">About</NavLink>
					</li>
				</ul>
			</nav>
		</header>
	)

}

export default Header;